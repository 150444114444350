@import url('https://fonts.googleapis.com/css2?family=Baskervville&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amiri&family=Baskervville&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  margin: 0;
  padding:0;
  font-family: 'Poppins', serif;
  background-color: black;
}

.baskerville {
  font-family: 'Baskervville', serif;
}

.amiri {
  font-family: 'Amiri', serif;
}

.hero {
  background-image: url('../src/images/hero.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.ellipse {
  background-image: url('../src/images/ellipse.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media screen and (max-width: 1470px) {
  .gallery-images {
    height: 200px;
  }
}

@media screen and (max-width: 1780px) {
  .review-boxes {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 1370px) {
  .review-boxes {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media screen and (max-width: 1070px) {
  .review-boxes {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 1030px) {
  .review-boxes {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 725px) {
  .review-boxes {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding-left: 0;
    padding-right: 0;
  }
  
  .review-boxes-hide {
    display: none;
  }
}